import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"

// Import Components
import Layout from "../components/layout"
import SEO from "../components/seo"

// Import Reveal Effects
import Bounce from 'react-reveal/Bounce';
import Fade from 'react-reveal/Fade';

const RequestSent = () => {
  const data = useStaticQuery(graphql`
    query {
      rooamApp: file(relativePath: { eq: "phones/Rooam-App-Livefeed-Full.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      rooamPay: file(relativePath: { eq: "phones/Rooam-Pay-Check-Full.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)  
  return (
    <Layout>
      <SEO title="Request sent - Rooam" />
      <div className="section more-top no-bottom">
        <div className="container medium text-center">
          <Bounce delay={400}>
            <div className="peak-wrap no-top">
              <div className="green-circle">
                <div className="green-inner">
                  <span className="icon tick">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M9 21.035l-9-8.638 2.791-2.87 6.156 5.874 12.21-12.436 2.843 2.817z"/></svg>
                  </span>
                </div>
              </div>
            </div>
          </Bounce>
          <h1 className="text-green no-top">Request sent</h1>
          <p className="lead text-light-grey no-bottom">A Rooam team member will be in touch with you within the next 24-48 hours. If you have any other inquiries, please email us at <a href="mailto:business@rooam.co" target="_blank" className="text-link-green" rel="noopener noreferrer">business@rooam.co</a></p>
          <Link to="/" className="btn light no-bottom-margin">Back Home</Link>
        </div>
      </div>
      <div className="section">
        <div className="container medium">
          <div className="row">
            <Fade bottom distance={'1em'}>
              <div className="column">
                <div className="padding more no-top">
                  <Img
                    fluid={data.rooamApp.childImageSharp.fluid}
                    alt="Rooam app tab with ordered items"
                    loading="eager"
                  />
                </div>
              </div>
            </Fade>
            <Fade bottom distance={'1em'} delay={200}>
              <div className="column">
                <div className="padding more no-top">
                  <Img
                    fluid={data.rooamPay.childImageSharp.fluid}
                    alt="Rooam pay check with ordered items"
                    loading="eager"
                  />
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default RequestSent
